<template>
  <v-card
    outlined
    rounded="md"
    class="product-card"
    :class="promoClasses"
    :to="{ name: 'Product', params: { slug: product.slug } }"
  >
    <div
      class="product-card-header d-flex flex-column justify-space-between align-center mb-2 mb-sm-4 w-100 top"
    >
      <ProductPromo :product="product" @setPromoClass="setPromoClass" />
      <v-spacer />
      <div
        class="product-card-header-block-2 d-flex flex-column align-end w-100 "
      >
        <div class="d-flex align-center justify-end w-100 mr-2">
          <ProductAcqLimit :product="product" />
          <v-tooltip left :key="heartKey">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                @click.stop.prevent="addToFavorites"
                @mousedown.stop
              >
                <v-icon :color="highlight ? 'primary' : 'grey'">
                  $heartfull
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t("product.addToList") }}</span>
          </v-tooltip>
        </div>
        <div class="d-flex flex-column">
          <ProductClass
            :pClass="pClass"
            v-for="pClass in productClasses"
            :key="pClass.productClassid"
          />
        </div>
      </div>
    </div>
    <div
      class="card-content w-100 px-1 h-100 d-flex flex-column justify-space-between"
      style="box-sizing: border-box"
    >
      <div class="d-flex flex-row flex-sm-column desc-wrapper">
        <img
          :src="product.mediaURL"
          onerror="this.onerror=null;this.src='/no-icon.png'"
          class="product-img align-self-center"
          :width="$vuetify.breakpoint.xs ? 110 : 150"
          :height="$vuetify.breakpoint.xs ? 110 : 150"
          :alt="'Immagine' + product.name"
          :title="
            product.name + ' (' + product.codInt + '-' + product.codVar + ')'
          "
        />
        <div class="w-100">
          <product-name :product="product" />
          <ProductPrice class="mx-1" :product="product" :showStadard="true" />
        </div>
      </div>

      <div class="actions justify-end">
        <template v-if="product.available > 0">
          <div
            class="qty-wrap rounded-md"
            :class="{ 'not-empty': quantity > 0 }"
            transition="fab-transition"
          >
            <button
              role="button"
              class="minus"
              @click.stop.prevent="minus"
              @mousedown.stop
              v-ripple
            >
              <v-icon>$minus</v-icon>
            </button>
            <div class="val-cnt">
              <span class="val">{{ quantity }} {{ unit }}</span>
              <span class="small ml-1">{{ quantityPerUnit }}</span>
            </div>
            <button
              role="button"
              class="plus"
              @click.stop.prevent="plus"
              @mousedown.stop
              v-ripple
            >
              <v-icon>$plus</v-icon>
            </button>
          </div>
          <div
            v-if="
              product.productInfos.ENABLE_ACQ_BOX == 'true' &&
                product.logisticPackagingItems
            "
            class="logistic-packagin-items secondary lighten-3 d-flex align-center justify- white--text rounded px-2 mr-1"
          >
            <span class="text-uppercase text-right">
              <strong>+{{ product.logisticPackagingItems }}</strong>
              <br />
              {{ $t("product.pieces") }}
            </span>
            <v-icon class="ml-1" color="white">$box</v-icon>
          </div>
          <v-btn
            color="secondary"
            class="add-to-cart"
            elevation="0"
            @click.stop.prevent="addToCart(quantity)"
          >
            <v-icon>$cart</v-icon></v-btn
          >
        </template>

        <div v-else class="not-available">
          {{ $t("product.notAvailable") }}
        </div>
      </div>
    </div>
  </v-card>
</template>
<style lang="scss">
.product-card {
  height: $product-card-height;
  .product-card-header {
    z-index: 2;
  }
  .description {
    text-align: center;
    align-items: center;
    .code-container {
      display: none;
    }
  }
}
.actions {
  min-height: 42px;
}
.not-available {
  font-size: 10px;
  line-height: 15px;
}
.top {
  height: $promo-box-height;
}
.yellow_box {
  border-color: var(--v-yellow-base);
}
.red_box {
  border-color: var(--v-primary-base);
}
.green_box {
  border-color: #50d708;
}
.orange_box {
  border-color: #f58a09;
}
.dark_green_box {
  border-color: #35ad04;
}
.dark_orange_box {
  border-color: #f05738;
}
.light_blue_box {
  border-color: #7baeff;
}
.brown_box {
  border-color: #6a4a3a;
}
@media #{map-get($display-breakpoints, 'xs-only')} {
  .has-promo .card-content {
    margin-top: 10px;
  }
  .product-card {
    height: $product-card-mobile-height;
  }
  .top {
    height: $promo-box-height-mobile;
  }
}
</style>
<script>
//TODO sistemare la visualizzazione della card su iphone SE dimensione della description
// import ProductInfo from "./ProductInfo.vue";
import ProductClass from "./ProductClass.vue";
import ProductAcqLimit from "./ProductAcqLimit.vue";
import ProductPrice from "./ProductPrice.vue";
import ProductName from "./ProductName.vue";
import ProductPromo from "@/components/product/ProductPromo.vue";

// import LeadTime from "./ProductLeadTime.vue";
// import LockCutoff from "./ProductLockCutoff.vue";
// import AxBCutoff from "./ProductAxBCutoff.vue";
// import DayLock from "./ProductDayLock.vue";

import productMixin from "~/mixins/product";

import { mapState, mapGetters } from "vuex";

export default {
  name: "ProductCard",
  props: ["product"],
  components: {
    // ProductInfo,
    ProductClass,
    ProductAcqLimit,
    ProductPromo,
    ProductPrice,
    ProductName
    // LeadTime,
    // LockCutoff,
    // AxBCutoff,
    // DayLock
  },
  mixins: [productMixin],
  data() {
    return {
      promoClasses: ""
    };
  },
  computed: {
    ...mapGetters({
      getItemByProductId: "cart/getItemByProductId"
    }),
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    item() {
      return null;
    },

    productClasses() {
      if (global.config.filterProductClasses && this.product.productClasses) {
        return this.product.productClasses.filter(
          p =>
            global.config.filterProductClasses.includes(p.productClassId) &&
            p.productClassTypeId == 2 &&
            p.productClassGroupId != 1
        );
      } else {
        return this.product.productClasses.filter(
          p => p.productClassTypeId == 2 && p.productClassGroupId != 1
        );
      }
    }
  },
  methods: {
    setPromoClass(promoClass) {
      this.promoClasses = promoClass;
    }
  }
};
</script>
