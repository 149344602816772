<template>
  <div>
    <div class="d-flex" v-if="isPDP">
      <v-tooltip
        left
        v-if="productLimit"
        max-width="200"
        color="secondary lighten-3"
      >
        <template v-slot:activator="{ on, attrs }">
          <div class="max-acq product-limit" v-bind="attrs" v-on="on">
            <span>max</span>
            <span>{{ productLimit }} pz</span>
          </div>
        </template>
        <span v-text="productTooltip"> </span>
      </v-tooltip>

      <v-tooltip right v-if="categoryLimit" max-width="200" color="secondary">
        <template v-slot:activator="{ on, attrs }">
          <div class="max-acq category-limit" v-bind="attrs" v-on="on">
            <span>max</span>
            <span>{{ categoryLimit }} pz</span>
          </div>
        </template>
        <span v-text="categoryTooltip"> </span>
      </v-tooltip>
    </div>
    <div class="d-flex" v-else>
      <v-tooltip
        left
        v-if="productLimit"
        max-width="200"
        color="secondary lighten-3"
      >
        <template v-slot:activator="{ on, attrs }">
          <div class="max-acq product-limit" v-bind="attrs" v-on="on">
            <span>max </span>
            <span>{{ productLimit }} pz</span>
          </div>
        </template>
        <span v-text="productTooltip"> </span>
      </v-tooltip>

      <v-tooltip
        right
        v-else-if="categoryLimit"
        max-width="200"
        color="secondary"
      >
        <template v-slot:activator="{ on, attrs }">
          <div class="max-acq category-limit" v-bind="attrs" v-on="on">
            <span>max</span>
            <span>{{ categoryLimit }} pz</span>
          </div>
        </template>
        <span v-text="categoryTooltip"> </span>
      </v-tooltip>
    </div>
  </div>
</template>
<style scoped lang="scss">
.max-acq {
  //width: 36px;
  max-height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
  span {
    text-transform: uppercase;
    padding: 2px 2px;
    color: #ffffff;
    line-height: 1;
    font-size: 10px;
    font-weight: bold;
    display: flex;
    text-align: center;
    border-radius: $border-radius-root;
    justify-content: center;
    align-items: center;
  }
  &.product-limit,
  &.category-limit {
    border-radius: $border-radius-root;
    padding: 10px 5px;
    flex-direction: column;
    padding: 1px 5px;
  }
  &.product-limit {
    background-color: var(--v-secondary-lighten3);
    span {
      //  background-color: var(--v-secondary-lighten3);
    }
  }
  &.category-limit {
    background-color: $secondary;
    span {
      //   background-color: $secondary;
    }
  }
}
</style>
<script>
import get from "lodash/get";
export default {
  name: "ProductAcqLimit",
  props: {
    product: {
      type: Object
    },
    isPDP: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      useProductClassRuleMax: false
    };
  },
  computed: {
    categoryLimit() {
      let max = get(this.product, "productClassRuleMax.max", 0);
      let multiPack = get(this.product, "productInfos.MULTI_PACK", 1);
      return max / multiPack;
    },
    productLimit() {
      let max = this.product.maxAcq;
      let multiPack = get(this.product, "productInfos.MULTI_PACK", 1);
      return max / multiPack;
    },

    /*    categoryLimit() {
      return get(this.product, "productClassRuleMax.max", 0);
    },
    productLimit() {
      return this.product.maxAcq;
    },*/
    categoryTooltip() {
      if (this.categoryLimit) {
        return this.$t(
          "product.maxAcqClassTooltip",
          this.product.productClassRuleMax
        );
      }
      return "";
    },
    productTooltip() {
      if (this.productLimit) {
        return this.$t("product.maxAcqTooltip", { count: this.productLimit });
      }
      return "";
    }
  }
};
</script>
