<template functional>
  <div class="description">
    <span class="name font-weight-bold">{{ props.product.name }}</span>
    <span class="brand text-caption">{{ props.product.shortDescr }}</span>
    <span class="descr">
      <span
        class="weight-unit rounded-md default--text font-weight-bold"
        v-if="
          props.product.productInfos.TIPOLOGIA == 'Pezzo' &&
            props.product.productInfos.PACKAGE_DESCR
        "
      >
        {{ props.product.productInfos.PACKAGE_DESCR }}
      </span>
      {{ props.product.description }}
      <span
        class="price-um font-weight-bold"
        v-if="props.product.priceUmDisplay"
      >
        <br />
        al&nbsp;{{ props.product.weightUnitDisplay }}&nbsp;{{
          parent.$n(props.product.priceUmDisplay, "currency")
        }}
      </span>
    </span>
    <span class="code-container white--text secondary my-2">
      Cod.Art
      <strong>
        {{ props.product.code }}
        {{ props.product.codeVariant }}
      </strong>
    </span>
  </div>
</template>
<style lang="scss">
.description {
  display: flex;
  flex-direction: column;
}
.code-container {
  padding: 4px;
  border-radius: $border-radius-root-small;
  font-size: 10px;
  width: fit-content;
}
@media #{map-get($display-breakpoints, 'xs-only')} {
  .description {
    width: calc(100% - 35px);
  }
}
</style>
<script>
export default {
  name: "ProductName",
  props: {
    product: { type: Object, required: true },
    full: { type: Boolean, default: false }
  }
};
</script>
